import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataTransferService {
  constructor(private http: HttpClient, private router: Router) {}

  // getAllBrands() {
  //   return this.http.get(`${environment.apiBaseUrl}/answers/getAllanswers`);
  // }

  // addBrand(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/brands/newBrand`,
  //     postData
  //   );
  // }

  // getBrandByID(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/brands/SearchBrands`,
  //     postData
  //   );
  // }

  // updateBrand(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/brands/updateBrand`,
  //     postData
  //   );
  // }

  // getAllCar(modelID: number) {
  //   return this.http.get(`${environment.apiBaseUrl}/car/getAllCar/` + modelID);
  // }

  // addCar(postData: any) {
  //   return this.http.post(`${environment.apiBaseUrl}/car/newCar`, postData);
  // }

  // updateCar(postData: any) {
  //   return this.http.put(`${environment.apiBaseUrl}/car/updateCar`, postData);
  // }

  // getCarByID(postData: number) {
  //   //return this.http.post(`${environment.apiBaseUrl}/car/SearchCar`,postData);
  //   return this.http.get(
  //     `${environment.apiBaseUrl}/car/getCarByID/` + postData
  //   );
  // }

  // updateCarGraphic(postData: FormData) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/graphic/updateCarGraphics`,
  //     postData
  //   );
  // }

  // getCarGraphic(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/graphic/Searchgraphic`,
  //     postData
  //   );
  // }

  // loginUser(postData:any){
  //   return this.http.post(`${environment.apiBaseUrl}/login`,postData);
  // }

  // getAllModel(brandID: number) {
  //   return this.http.get(
  //     `${environment.apiBaseUrl}/Model/getAllModel/` + brandID
  //   );
  // }

  // getAllCategory() {
  //   return this.http.get(`${environment.apiBaseUrl}/category/getAllCategory`);
  // }

  // getAllOptions() {
  //   return this.http.get(`${environment.apiBaseUrl}/options/getAllOptions`);
  // }

  // getAllcategorytype() {
  //   return this.http.get(`${environment.apiBaseUrl}/answers/getAllanswers`);
  // }

  // getAllStaterto() {
  //   return this.http.get(`${environment.apiBaseUrl}/staterto/getAllStaterto`);
  // }

  // updateStaterto(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/staterto/updateStaterto`,
  //     postData
  //   );
  // }

  // getAllGraphictype() {
  //   return this.http.get(
  //     `${environment.apiBaseUrl}/graphictype/getAllGraphictype`
  //   );
  // }

  // getAllvariable() {
  //   return this.http.get(`${environment.apiBaseUrl}/variable/getAllvariable`);
  // }

  // addModel(postData: any) {
  //   return this.http.post(`${environment.apiBaseUrl}/Model/newModel`, postData);
  // }

  // addCategory(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/category/newCategory`,
  //     postData
  //   );
  // }

  // updateCategory(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/category/updateCategory`,
  //     postData
  //   );
  // }

  // addCategorytype(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/answers/newAnswers`,
  //     postData
  //   );
  // }

  // updateCategorytype(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/categorytype/updatecategorytype`,
  //     postData
  //   );
  // }

  // addGraphictype(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/graphictype/newGraphictype`,
  //     postData
  //   );
  // }

  // updateGraphictype(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/graphictype/updatGraphictype`,
  //     postData
  //   );
  // }

  // getGraphictypeByID(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/graphictype/SearchGraphictype`,
  //     postData
  //   );
  // }

  // addOption(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/options/newOptions`,
  //     postData
  //   );
  // }

  // updateOption(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/options/updateOptions`,
  //     postData
  //   );
  // }

  // addStaterto(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/staterto/newStaterto`,
  //     postData
  //   );
  // }

  // addVariable(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/variable/Addnewvariable`,
  //     postData
  //   );
  // }

  // updateVariable(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/variable/updatevariable`,
  //     postData
  //   );
  // }

  // updateModel(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/Model/updateModel`,
  //     postData
  //   );
  // }

  // getModelByID(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/Model/SearchModel`,
  //     postData
  //   );
  // }
  
  getAllAdminUsers() {
    return this.http.get(`${environment.apiBaseUrl}/getUserAdmin`);
  }

  getAllCompany() {
    return this.http.get(`${environment.apiBaseUrl}/get_all_company`);
  }

  getCompanyById(companyId:any){
    return this.http.get(`${environment.apiBaseUrl}/getCompanyById`, {
      params: { companyId },
    });
  }

  updateUser(postData: any) {
    return this.http.put(`${environment.apiBaseUrl}/user/updateUser`, postData);
  }

  updateRole(postData: any) {
    return this.http.put(`${environment.apiBaseUrl}/role/updateRole`, postData);
  }
  addRole(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/role/newRole`, postData);
  }

  addOpportunity(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/insert_job_description`,
      postData
    );
  }

  getAllSchemeByCompanyId(companyId: any) {
    return this.http.get(`${environment.apiBaseUrl}/get_jobs_by_companyid`, {
      params: { companyId },
    });
  }

  getAllRoleBySectorId(CO_sectorId: any) {
    return this.http.get(`${environment.apiBaseUrl}/get_role_titles`, {
      params: { CO_sectorId },
    });
  }


  // getAllJobByJobId(JB_id: any) {
  //   return this.http.get(`${environment.apiBaseUrl}/getJobsById`, {
  //     params: { JB_id },
  //   });
  // }

  GenerateTranscriptById(AN_id: any) {
    return this.http.get(`${environment.apiBaseUrl}/GenerateTranscriptById`, {
      params: { AN_id },
    });
  }

  updateTranscription(postData: any) {
    return this.http.put(`${environment.apiBaseUrl}/updateTranscription`,postData);
  }

  generateAnnonymousInsight(AN_id: any){
    return this.http.get(`${environment.apiBaseUrl}/generateTranscriptToAudio`, {
      params: { AN_id },
    });
  }

  // GenerateTranscriptById(postData: any) {
  //   return this.http.post(`${"http://3.11.71.19"}/process_audio`, postData);
  // }

  DeleteJobsById(JB_id: any) {
    return this.http.delete(`${environment.apiBaseUrl}/DeleteJobsById`, {
      params: { JB_id },
    });
  }

  updateJobs(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updateJobs`, postData);
  }

  getSectorTitles() {
    return this.http.get(`${environment.apiBaseUrl}/get_sector_titles`);
  }

  addEmployer(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/insert_company`, postData);
  }

  updateEmployer(postData:any){
    return this.http.patch(`${environment.apiBaseUrl}/update_company`, postData);
  }

  getRoleByID(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/role/SearchRole`,
      postData
    );
  }

  // getAllCount() {
  //   return this.http.get(`${environment.apiBaseUrl}/user/UserCount`);
  // }

  // getOptionsByCategoryID(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/options/SearchOptions`,
  //     postData
  //   );
  // }

  // getCategoryChildCount(ID: number) {
  //   return this.http.get(
  //     `${environment.apiBaseUrl}/category/parentCount/` + ID
  //   );
  // }

  // getAllmodeltype() {
  //   return this.http.get(
  //     `${environment.apiBaseUrl}/model_type/getAllModelType`
  //   );
  // }

  // updateModeltype(postData: any) {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/model_type/updateModeltype`,
  //     postData
  //   );
  // }

  // addModeltype(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/model_type/newModelType`,
  //     postData
  //   );
  // }

  getAllAccess() {
    return this.http.get(`${environment.apiBaseUrl}/access/getAllAccess`);
  }
  getRoleAccess(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/roleaccess/SearchroleAccess`,
      postData
    );
  }
  getAccessLinksForRole(roleId: number) {
    return this.http.get(
      `${environment.apiBaseUrl}/roleaccess/getAccessLinksByRole/` + roleId
    );
  }
  getModelDoc(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/model_doc/SearchModelDoc`,
      postData
    );
  }
  updateModelDoc(postData: FormData) {
    return this.http.post(
      `${environment.apiBaseUrl}/model_doc/updateModelDoc`,
      postData
    );
  }
  resetPassword(postData: FormData) {
    return this.http.post(
      `${environment.apiBaseUrl}/users/resetPassword`,
      postData
    );
  }
  getOptionByCategory(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/graphic/SearchOptions`,
      postData
    );
  }

  // getAllUser() {
  //   return this.http.get(`${environment.apiBaseUrl}/getAllUsers`);
  // }

  // Future selves Datatransfer-Services start Here


  loginUser(postData: any) {
  return this.http.post(`${environment.apiBaseUrl}/login_user`, postData);
}
  

  getSoundBite() {
    return this.http.get(`${environment.apiBaseUrl}/getallvoxpod`);
  }

  getSoundbiteByid(postData:any){
    return this.http.get(`${environment.apiBaseUrl}/getallreligion`,{params: { AN_id:postData }},
    );
  }

  getAllInsights() {
    return this.http.get(`${environment.apiBaseUrl}/getQuestionTitle`);
  }

  getUserData(ID: any) {
    return this.http.get(`${environment.apiBaseUrl}/getuserdatabyid?id=${ID}`);
  }

  getdegree() {
    return this.http.get(`${environment.apiBaseUrl}/getroles`);
  }


  getethinicity() {
    return this.http.get(`${environment.apiBaseUrl}/ethnicity`);
  }

  // getcountry() {
  //   return this.http.get(`${environment.apiBaseUrl}/country`);
  // }

  // getindustry() {
  //   return this.http.get(`${environment.apiBaseUrl}/industry`);
  // }

  getregionalAccent() {
    return this.http.get(`${environment.apiBaseUrl}/regional_accent`);
  }

  getsexualOrientation() {
    return this.http.get(`${environment.apiBaseUrl}/sexual_orientation`);
  }

  getreligion() {
    return this.http.get(`${environment.apiBaseUrl}/religion`);
  }

  // getfollowers() {
  //   return this.http.get(`${environment.apiBaseUrl}/getsingleidfollowers`);
  // }

  // getpages() {
  //   return this.http.get(`${environment.apiBaseUrl}/pages`);
  // }

  // getquiz() {
  //   return this.http.get(`${environment.apiBaseUrl}/quiz`);
  // }

  // gettagtitle(ID: any) {
  //   return this.http.get(`${environment.apiBaseUrl}/tags/${ID}`);
  // }

  uploadurl(postData: File) {
    // const formData: FormData = new FormData();
    // formData.append('file', postData);
  
    console.log("postData", postData);
  
    return this.http.put(
      `https://4yr4616jve.execute-api.eu-west-2.amazonaws.com/api/voxpod/${postData.name}`,
      postData 
    );
  }
  
  

  gettags() {
    return this.http.get(`${environment.apiBaseUrl}/testtags`);
  }

  updateStatus(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updateStatus`, postData);
  }
  
  updateAllData(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updatetagtitle`,
      postData
    );
  }
  
  insightRejectedNotification(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/insightRejectedNotification`,
      postData
    );
  }

  insightAcceptedNotification(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/insightAcceptedNotification`,
      postData
    );
  }

  

  updateDegree(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updatedegree`, postData);
  }

  updateregional(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/allregionalupdate`,
      postData
    );
  }

  updateEthinicity(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/allupdateethnicity`,
      postData
    );
  }

  updateIndustry(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updateindustry`,
      postData
    );
  }

  updatesexualOrientation(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updatesexualorientation`,
      postData
    );
  }

  updatereligion(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updatereligions`,
      postData
    );
  }

  updatepages(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updatepages`, postData);
  }

  updatefollowers(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updatefollowers`,
      postData
    );
  }

  addfollowers(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/addfollowers`, postData);
  }

  addpages(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/addpages`, postData);
  }

  addIndustry(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/saveindustry`, postData);
  }

  addcountry(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/savecountry`, postData);
  }

  // addDegree(postData: any) {
  //   return this.http.post(
  //     `${environment.apiBaseUrl}/allupdatedegrees`,
  //     postData
  //   );
  // }

  // addnewDegree(postData: any) {
  //   return this.http.post(`${environment.apiBaseUrl}/adddegrees`, postData);
  // }

  addreligion(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/savereligions`, postData);
  }

  addEthnicity(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/saveethnicity`, postData);
  }

  addregionalAccent(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/saveregionalaccent`,
      postData
    );
  }

  addsexualOrientation(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/savesexualorientation`,
      postData
    );
  }

  addcollection(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/saveplaylist`, postData);
  }

  getCollection() {
    // return this.http.get(`${environment.apiBaseUrl}/getallplaylist`);getallvoxpod
    return this.http.get(`${environment.apiBaseUrl}/getPlaylists`);
  }

  deletecollection(PL_id: any) {
    return this.http.delete(`${environment.apiBaseUrl}/deletePlaylist`, {
      params: { PL_id },
    });
  }

  allupdatedegree(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/allupdatedegrees`,
      postData
    );
  }
  updateRejectReason(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updateRejectReason`, postData);
  }

  updateCollection(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updateCollection`,
      postData
    );
  }

  updateAllplayList(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updateplaylist`,
      postData
    );
  }
  updateAll(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updatedprecord`,
      postData
    );
  }

  // Code Start By Omkar
  filterResultData(postData: any) {
    return this.http.get(`${environment.apiBaseUrl}/searchSharerData`, {
      params: {
        AN_industryId: postData.AN_industryId,
        AN_degreeId: postData.AN_degreeId,
        AN_questionId: postData.AN_questionId,
        AN_userId: postData.AN_userId,
      },
    });
  }

  search(searchText: string) {
    const params = new HttpParams().set('text', searchText);
    return this.http.get(
      `${environment.apiBaseUrl}/searchsoundbitesandprofiles`,
      { params }
    );
  }

  // public search(searchFileds: string) {
  //   console.log('searchFileds', searchFileds);
  //   return this.http.post(`${environment.apiBaseUrl}/searchsoundbitesandprofiles`, {
  //     params: {
  //       text: searchFileds,
  //     }
  //   });
  // }

  // addNewVoxList(postData:any){
  //   return this.http.put(`${environment.apiBaseUrlRushi}/VOXPOD_MASTERS_2`,postData);
  // }

  addNewVoxList(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/savePlaylists`, postData);
  }

  getIndustryData() {
    return this.http.get(`${environment.apiBaseUrl}/getIndustryData`);
  }

  insertIndustryData(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/insertIndustryData`,
      postData
    );
  }

  deleteIndustryData(IN_id: any) {
    return this.http.delete(`${environment.apiBaseUrl}/deleteIndustryData`, {
      params: { IN_id },
    });
  }

  insertRoleData(postData: any) {
    return this.http.post(`${environment.apiBaseUrl}/insertRoleData`, postData);
  }

  updateRoleData(postData: any) {
    return this.http.put(`${environment.apiBaseUrl}/updateRoleData`, postData);
  }

  deleteRoleData(RO_id: any) {
    return this.http.delete(`${environment.apiBaseUrl}/deleteRoleData`, {
      params: { RO_id },
    });
  }
  updateIndustryData(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updateIndustryData`,
      postData
    );
  }
  getAllExpctedActivities() {
    return this.http.get(`${environment.apiBaseUrl}/getQuizTitle`);
  }

  addUserTypesRole(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/addUserTypesRole`,
      postData
    );
  }

  getAllRole() {
    return this.http.get(`${environment.apiBaseUrl}/get_user_type`);
  }

  deleteUserTypeRole(UT_id: any) {
    return this.http.delete(`${environment.apiBaseUrl}/deleteUserTypeRole`, {
      params: { UT_id },
    });
  }

  addUser(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/addUserAndSendEmail`,
      postData
    );
  }

  setPassword(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/validateOTPAndSavePassword`,
      postData
    );
  }

  getAllAppUsers() {
    return this.http.get(`${environment.apiBaseUrl}/usersDetails`);
  }

  getUserDetailsById(userId:any) {
    return this.http.get(`${environment.apiBaseUrl}/getUserDetailsById`,{
      params: { userId },
    });
  }

  updateActiveStatus(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/updateActiveStatus`,
      postData
    );
  }

  getAllUserPreferences(userId: any) {
    return this.http.get(`${environment.apiBaseUrl}/userPreferences`, {
      params: { userId },
    });
  }

  getAllPreferencesData() {
    return this.http.get(`${environment.apiBaseUrl}/adminGetAllquizs`);
  }

  getIdentityData() {
    return this.http.get(`${environment.apiBaseUrl}/adminGetAllLists`);
  }

  getAllUniversity() {
    return this.http.get(`${environment.apiBaseUrl}/adminGetAllInstitute`);
  }

  addUniversity(postData:any){
    return this.http.post(`${environment.apiBaseUrl}/addInstitute`,postData);
  }

  updateInstitute(postData:any){
    return this.http.put(`${environment.apiBaseUrl}/updateInstitute`,postData);
  }

  deleteInstituteById(INS_id:any){
    return this.http.delete(`${environment.apiBaseUrl}/deleteInstituteById`,{params:{INS_id}})
  }

  getInstituteById(instituteId:any){
    return this.http.get(`${environment.apiBaseUrl}/getInstituteById`,{params:{instituteId}});
  }

  getAllDegree() {
    return this.http.get(`${environment.apiBaseUrl}/adminGetEducationDegree`);
  }

  addDegree(postData:any){
    return this.http.post(`${environment.apiBaseUrl}/addEducationDegree`,postData);
  }

  createNewAppUser(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/adminUserCreate`,
      postData
    );
  }

  updateUserIdentity(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updateUserIdentity`,postData);
  }

  updateUserPreferences(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updateUserPreference`,postData);
  }

  updateUserDetails(postData: any) {
    return this.http.patch(`${environment.apiBaseUrl}/updateUserDetails`,postData);
  }

  getAllPosition(U_id: any) {
    return this.http.get(
      `${environment.apiBaseUrl}/adminGetUserExperienceById`,
      { params: { U_id } }
    );
  }

  getAllQuestionsForPosition(industryId: any,userId:any,degreeId:any) {
    return this.http.get(`${environment.apiBaseUrl}/adminGetAllQuestions`, {
      params: { 
        AN_userId:userId,
        AN_industryId:industryId,
        AN_degreeId:degreeId
       },
      
    });
  }

  getExpertQuestionsBySectorId(industryId: any,userId:any,degreeId:any) {
    return this.http.get(`${environment.apiBaseUrl}/getExpertQuestionsBySectorId`, {
      params: { 
        AN_industryId:industryId,
        AN_userId:userId,
        AN_degreeId:degreeId,
        QU_expSectorId:1
       },
      
    });
  }

  savePosition(postData: any) {
    return this.http.patch(
      `${environment.apiBaseUrl}/adminUpdateuserIndustries`,
      postData
    );
  }

  
  uploadNewInsight(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/admInAddInsight`,
      postData
    );
  }

  updateInsight(postData: any) {
    return this.http.put(
      `${environment.apiBaseUrl}/updateInsight`,
      postData
    );
  }

  getLikedSectors(LI_userId: any) {
    return this.http.get(`${environment.apiBaseUrl}/getLikedSectors`, {
      params: { LI_userId },
    });
  }

  generateNotification(postData: any) {
    return this.http.post(
      `${environment.apiBaseUrl}/postBroadcastingNotifications`,
      postData
    );
  }

  getChartData(type: any) {
    return this.http.get(`${environment.apiBaseUrl}/registration-analytics`, {
      params: { type },
    });
  }

  getAllInsightSummary(){
    return this.http.get(`${environment.apiBaseUrl}/insights-summary`);
  }

  getAllInsightChartData(interval:any){
    return this.http.get(`${environment.apiBaseUrl}/getAllUsersInfoDetails`,{params:{interval}})
  }

  getBadgeData(userId:any,industryId:any,roleId:any){
    // const userId="9d067630-a582-43ff-bc22-18610970418f";
    return this.http.get(`${environment.apiBaseUrl}/getBadgeDataAdmin`,{params:{
      userId:userId,
      industryId:industryId,
      degreeId:roleId
    }})
  }

  saveBadge(postData:any){
    return this.http.post(`${environment.apiBaseUrl}/saveBadgeBySharer`,postData);
  }
}
